import markImg from '../assets/images/camera.png';

const { Cesium } = window;
/**
 几何类型（GeoType），
 属性（Attributes），
 标注名称（Name必填），
 类型（Type），
 备注（ReMark），
 位置数组（Positions），
 风格（Styles），
 填充颜色（FillColor），
 透明度（Opacity），
 是否按照视距显示（IsDistanceShow），
 最大距离（MaxDistance），
 最小距离（MinDistance），
 层级顺序（ZIndex），
 是否贴地（IsClamp），
 是否按照距离缩放（IsDistanceZoom），
 上限值（UpperLimit），
 下限值（LowerLimit），
 上限比例值（UpperScale），
 下限比例值（LowerScale），
 是否遮挡（IsDepthTest），
 是否背景（IsBackGround），
 背景颜色（BackGroundColor），
 背景透明度（BackColorOpacity），
 背景内边距（BackPadding），
 背景横向偏移值（BackOffsetX），
 背景竖向偏移值（BackOffsetY），
 比例（Scale），
 旋转角度（Rotate）
 * */
const configs = {
  GeoType: null, // 几何类型
  Attributes: null, // 属性
  Name: '', // 标注名称
  Type: null, // 类型
  ReMark: null, // 备注
  Positions: [
    {
      Longitude: '', // 经度
      Latitude: '', // 纬度
      Elevation: '' // 高程
    }
  ], // 位置数组
  DefaultNumAdd: null, // 原值上增加
  DefaultNumChange: null, // 全修改为
  Content: null, // 内容
  Styles: {
    FontFamily: '楷体', // 字体
    FontSize: 16 // 字号
  }, // 风格
  FillColor: null, // 填充颜色
  Opacity: null, // 透明度
  IsBackGround: null, // 是否背景
  BackGroundColor: null, // 背景颜色
  BackColorOpacity: null, // 背景透明度
  BackPadding: null, // 背景内边距
  BackOffsetX: null, // 背景横向偏移值
  BackOffsetY: null, // 背景竖向偏移值
  Scale: null, // 比例
  Rotate: null // 旋转角度
};
const config = {
  text: {
    name: '文字',
    type: 'label',
    attr: {
      name: '',
      remark: '',
      type: '',
      id: null
    },
    style: {
      text: 'xx路',
      color: '#ffffff',
      opacity: 1,
      font_family: '楷体',
      font_size: 30,
      background: false,
      background_color: '#000000',
      background_opacity: 0.5,
      font_weight: 'normal',
      font_style: 'normal',
      clampToGround: false,
      visibleDepth: false,
      border: true,
      border_color: '#000000',
      border_width: 3
    },
    info: {
      GeoType: 'text', // 几何类型
      Attributes: null, // 属性
      Name: '', // 标注名称
      Type: null, // 类型
      ReMark: null, // 备注
      Positions: [
        {
          Longitude: '', // 经度
          Latitude: '', // 纬度
          Elevation: '' // 高程
        }
      ], // 位置数组
      DefaultNumAdd: null, // 原值上增加
      DefaultNumChange: null, // 全修改为
      Content: 'xx路', // 内容
      Styles: {
        FontFamily: '楷体', // 字体
        FontSize: 30 // 字号
        // FontWeight: false, // 加粗
        // FontStyle: false // 斜体
      }, // 风格
      FillColor: '#ffffff', // 填充颜色
      Opacity: 1, // 透明度
      IsDistanceShow: false, // 是否按照视距显示
      IsBackGround: false, // 是否背景
      BackGroundColor: null, // 背景颜色
      BackColorOpacity: null, // 背景透明度
      BackPadding: null, // 背景内边距
      BackOffsetX: null, // 背景横向偏移值
      BackOffsetY: null, // 背景竖向偏移值
      Scale: null, // 比例
      Rotate: null // 旋转角度
    }
  },
  icon: {
    name: '图标点标记',
    type: 'billboard',
    GeoType: 'icon',
    selectType: '',
    attr: {
      name: '',
      remark: '',
      id: null
    },
    style: {
      scale: 1,
      clampToGround: false,
      visibleDepth: true,
      image: markImg,
      horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM
    },
    info: {
      GeoType: 'icon', // 几何类型
      Attributes: null, // 属性
      Name: '', // 标注名称
      Type: null, // 类型
      ReMark: null, // 备注
      Positions: [
        {
          Longitude: '', // 经度
          Latitude: '', // 纬度
          Elevation: '' // 高程
        }
      ], // 位置数组
      DefaultNumAdd: null, // 原值上增加
      DefaultNumChange: null, // 全修改为
      Content: null, // 内容
      Styles: {
        FontFamily: null, // 字体
        FontSize: null // 字号
        // FontWeight: null, // 加粗
        // FontStyle: null // 斜体
      }, // 风格
      FillColor: '#ffffff', // 填充颜色
      Opacity: 1, // 透明度
      IsDistanceShow: false, // 是否按照视距显示
      IsBackGround: false, // 是否背景
      BackGroundColor: null, // 背景颜色
      BackColorOpacity: null, // 背景透明度
      BackPadding: null, // 背景内边距
      BackOffsetX: null, // 背景横向偏移值
      BackOffsetY: null, // 背景竖向偏移值
      Scale: null, // 比例
      Rotate: null // 旋转角度
    }
  },
  polyline: {
    name: '线',
    type: 'polyline',
    config: {
      minPointNum: 2
    },
    attr: {
      name: '',
      remark: '',
      id: null
    },
    style: {
      color: '#3388ff',
      clampToGround: true,
      opacity: 1,
      zIndex: 100,
      width: 3
    },
    info: {
      GeoType: 'polyline', // 几何类型
      Attributes: null, // 属性
      Name: '', // 标注名称
      Type: null, // 类型
      ReMark: null, // 备注
      Positions: [
        {
          Longitude: '', // 经度
          Latitude: '', // 纬度
          Elevation: '' // 高程
        }
      ], // 位置数组
      DefaultNumAdd: null, // 原值上增加
      DefaultNumChange: null, // 全修改为
      Content: null, // 内容
      Styles: {
        FontFamily: null, // 字体
        FontSize: null // 字号
        // FontWeight: null, // 加粗
        // FontStyle: null // 斜体
      }, // 风格
      FillColor: '#3388ff', // 填充颜色
      Opacity: 1, // 透明度
      IsBackGround: false, // 是否背景
      BackGroundColor: null, // 背景颜色
      BackColorOpacity: null, // 背景透明度
      BackPadding: null, // 背景内边距
      BackOffsetX: null, // 背景横向偏移值
      BackOffsetY: null, // 背景竖向偏移值
      Scale: null, // 比例
      Rotate: null // 旋转角度
    }
  },
  polygon: {
    name: '面',
    type: 'polygon',
    GeoType: 'polygon',
    attr: {
      name: '',
      remark: '',
      id: null
    },
    style: {
      color: '#3388ff',
      opacity: 0.6,
      clampToGround: true,
      zIndex: 100,
      outline: true,
      outlineWidth: 1,
      outlineColor: '#ffffff',
      outlineOpacity: 0.6
    },
    info: {
      GeoType: 'polygon', // 几何类型
      Attributes: null, // 属性
      Name: '', // 标注名称
      Type: null, // 类型
      ReMark: null, // 备注
      Positions: [
        {
          Longitude: '', // 经度
          Latitude: '', // 纬度
          Elevation: '' // 高程
        }
      ], // 位置数组
      DefaultNumAdd: null, // 原值上增加
      DefaultNumChange: null, // 全修改为
      Content: null, // 内容
      Styles: {
        FontFamily: null, // 字体
        FontSize: null // 字号
        // FontWeight: null, // 加粗
        // FontStyle: null // 斜体
      }, // 风格
      FillColor: '#3388ff', // 填充颜色
      Opacity: 0.6, // 透明度
      IsDistanceShow: false, // 是否按照视距显示
      IsBackGround: false, // 是否背景
      BackGroundColor: null, // 背景颜色
      BackColorOpacity: null, // 背景透明度
      BackPadding: null, // 背景内边距
      BackOffsetX: null, // 背景横向偏移值
      BackOffsetY: null, // 背景竖向偏移值
      Scale: null, // 比例
      Rotate: null // 旋转角度
    }
  }
};

export { config, configs };

/*
 * @Author: your name
 * @Date: 2022-01-09 20:44:01
 * @LastEditTime: 2023-04-13 23:25:20
 * @LastEditors: xjh
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \qyfk_zhen\src\map\layer\communityGridLyr.js
 */
import communityGridData from "./data/gridData";

const { mars3d, Cesium, turf } = window;

class CommunityGridLyr {
  constructor(options) {
    this.opts = options;
    this.layer = null;
    this.labelDs = null;
    this.addLyr();
  }

  addLyr() {
    const { name, viewer, visible4Lyr, visible4Label } = this.opts;
    this.layer = new mars3d.layer.GeoJsonLayer({
      name,
      data: communityGridData,
      symbol: {
        styleOptions: {
          fill: true,
          randomColor: false, // 随机色
          opacity: 0.0,
          outline: true,
          outlineColor: "#3a90db",
          outlineWidth: 2,
          clampToGround: true // 要放在最外围
        }
      },
      show: visible4Lyr,
      flyTo: true
    });
    viewer.addLayer(this.layer);

    this.layer.once("load", () => {
      this.layer.graphics.map(item => {
        const color = "#93ff93"; // '#00ff00';
        item.primitive.geometryInstances.attributes.color =
          Cesium.ColorGeometryInstanceAttribute.fromColor(
            Cesium.Color.fromCssColorString(color).withAlpha(0.0)
          );
        return item;
      });
      viewer.flyTo(this.layer);
    });
    this.labelDs = new Cesium.CustomDataSource("labelDs");
    viewer.dataSources.add(this.labelDs);
    communityGridData.features.map(item => {
      item.geometry.coordinates.map(jitem => {
        let center = null;
        let position = null;
        if (item.properties.centerPt) {
          center = {
            geometry: {
              coordinates: item.properties.centerPt
            }
          };
          position = Cesium.Cartographic.fromDegrees(
            item.properties.centerPt[0],
            item.properties.centerPt[1]
          );
        } else {
          center = turf.centerOfMass(turf.polygon([jitem]));
          position = Cesium.Cartographic.fromDegrees(
            center.geometry.coordinates[0],
            center.geometry.coordinates[1]
          );
        }

        const height = viewer.scene.sampleHeight(position);
        this.labelDs.entities.add({
          data: item,
          position: Cesium.Cartesian3.fromDegrees(
            center.geometry.coordinates[0],
            center.geometry.coordinates[1],
            height
          ),
          label: {
            show: visible4Label,
            text: item.properties["网格区"],
            font: "16px 楷体",
            outlineWidth: 3,
            outlineColor: Cesium.Color.fromCssColorString("#FED976"),
            style: {
              FILL: 0,
              FILL_AND_OUTLINE: 2,
              OUTLINE: 1
            },
            pixelOffset: new Cesium.Cartesian2(0, 0),
            // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            // clampToGround: true,
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            scaleByDistance: new Cesium.NearFarScalar(10000, 1.0, 500000, 0.1),
            // classificationType: Cesium.ClassificationType.CESIUM_3D_TILE
            disableDepthTestDistance: Number.POSITIVE_INFINITY
          }
        });
        this.labelDs.entities.add({
          data: item,
          position: Cesium.Cartesian3.fromDegrees(
            center.geometry.coordinates[0],
            center.geometry.coordinates[1],
            height
          ),
          label: {
            show: visible4Label,
            text: item.properties["序号"],
            font: "20px 楷体",
            outlineWidth: 3,
            outlineColor: Cesium.Color.fromCssColorString("#FED976"),
            style: {
              FILL: 0,
              FILL_AND_OUTLINE: 2,
              OUTLINE: 1
            },
            pixelOffset: new Cesium.Cartesian2(0, -20),
            // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            // clampToGround: true,
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            scaleByDistance: new Cesium.NearFarScalar(10000, 1.0, 500000, 0.1),
            // classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
            disableDepthTestDistance: Number.POSITIVE_INFINITY
          }
        });
        return jitem;
      });

      return item;
    });
    viewer.flyTo(this.labelDs.entities);
  }

  // 选中网格
  colorEntityById(id) {
    const graphic = this.layer.getGraphicByAttr("Id", id);
    graphic.setStyle({
      plane: new Cesium.Plane(Cesium.Cartesian3.UNIT_Z, 0.0),
      dimensions: new Cesium.Cartesian2(1000.0, 1000.0),
      color: "#93ff93",
      outline: true,
      outlineColor: "#3a90db",
      opacity: 0.6,
      clampToGround: true // 要放在最外围
    });
    graphic.flyTo();
  }

  // 重置网格颜色
  reSetEntityColor(id) {
    const graphic = this.layer.getGraphicByAttr("Id", id[0]);
    graphic.setStyle({
      plane: new Cesium.Plane(Cesium.Cartesian3.UNIT_Z, 0.0),
      dimensions: new Cesium.Cartesian2(1000.0, 1000.0),
      color: "#93ff93",
      outline: true,
      outlineColor: "#3a90db",
      opacity: 0.0,
      clampToGround: true // 要放在最外围
    });
  }

  // 重置全部网格颜色
  reSetAllEntityColor() {
    this.layer.graphics.map(graphic => {
      return graphic.setStyle({
        plane: new Cesium.Plane(Cesium.Cartesian3.UNIT_Z, 0.0),
        dimensions: new Cesium.Cartesian2(1000.0, 1000.0),
        color: "#93ff93",
        outline: true,
        outlineColor: "#3a90db",
        opacity: 0.0,
        clampToGround: true // 要放在最外围
      });
    });
  }

  // 设置图层显影
  setVisbility4Lyr(status) {
    this.layer.show = status;
    if (status) this.opts.viewer.flyTo(this.layer.dataSource.entities);
  }

  // 设置图层标注显影
  setVisbility4Label(status) {
    this.layer.dataSource.entities._entities._array.map(item => {
      if (item.label) {
        item._label.show = status;
      }
      return item;
    });
    this.labelDs.entities._entities._array.map(item => {
      if (item.label) {
        item._label.show = status;
      }
      return item;
    });
    if (status) this.opts.viewer.flyTo(this.layer.dataSource.entities);
  }

  setVisible(isShow) {
    this.layer.show = isShow;
    this.labelDs.show = isShow;
  }
}

export default CommunityGridLyr;

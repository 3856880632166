<template>
  <div class="mapmain">
    <div id="map3DView"></div>
    <div class="ui-container">
      <el-checkbox-group v-model="checkedCities" @change="handleChange">
        <el-checkbox class="title" v-for="(item, index) in cities" :label="item.value" :key="index">{{ item.label }}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="ui-container draw">
      <el-button type="primary" @click="backPre">返回上一页</el-button>
    </div>

    <!-- 弹层 -->
    <map-mode ref="mapMode"></map-mode>
    <!-- 设备 -->
    <EZUIKitJs ref="ezuik" />
  </div>
</template>
<script>
export let dmap // mars3d.Map三维地图对象
// import Vue from 'vue'
import CzmMap from '../map/map'
import DrawTool from '../map/tool/draw.js'
import { setCoordinates } from '../map/utils.js'
import CommunityGridLyr from '../map/layer/communityGridLyr.js'
import { getMapAll, getMapCameras } from '@/api/api.js'

import mapMode from '@/components/mapMode.vue'
import EZUIKitJs from '@/components/EZUIKitJs.vue'

// 设备点数据
var bussinessData = []
// 单体化示例数据
var buildingData = []
//线数据
var linedata = []
import cameraPng from '@/assets/img/camera.png'
export default {
  components: { mapMode, EZUIKitJs },
  data() {
    return {
      checked1: '',
      checkedCities: ['area'],
      cities: [{ label: '网格', value: 'area', checked: false }, { label: '房屋', value: 'house', checked: false }, { label: '场所建筑', value: 'place', checked: false }, { label: '设备点', value: 'monitor', checked: true }],
      changerow: { label: '网格', value: 'area', checked: true },
      bussinessLayer: '',
      clickMapFlag: false,
      communityGridLyr: '',
      drawTools: '',
      polygongraphicLayer: '',
      homeDestination: '',
      homeOrientation: '',
      currListGraphicLayer: '',
      lineLayer: '',
      allList: [],
      baseForm: {}
    }
  },
  mounted() {
    this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
    this.searchMaps()
    // this.drow()
  },
  methods: {
    searchMaps() {
      getMapAll().then(res => {
        if (res.code == 0) {
          if (res?.model) {
            let arr = []
            res.model.map(i => {
              if (i?.drawType) {
                i.drawType = i.drawType.split(',')
              }
              i.modelText = JSON.parse(i.modelText)
              if (this.checkedCities.includes(i.modelType)) {
                arr.push(i)
              }
            })
            this.allList = res.model
            this.changeJson(arr)

            this.$nextTick(() => {
              this.drow()
            })
          }
        }
      })
    },
    changeJson(data, type) {
      let staionJson = { type: 'FeatureCollection' } //定义一个空对象，因为是geojson所以默认加上格式。
      //以下代码将获取到的普通接口json转换为geojson的格式
      var features = []
      let poinArr = []
      let polArr = []
      let lineArr = []
      data.map(mapitem => {
        if (mapitem.modelText && mapitem.modelText.features) {
          mapitem.modelText.features.map(i => {
            if (i.properties.type == 'polyline') {
              let lineObj = {}
              lineObj.geometry = i.geometry
              lineObj.properties = i.properties
              lineObj.type = i.type
              lineObj.pData = mapitem
              lineObj.name = i.properties.style.label.text
              lineObj.villageId = this.baseForm.regionId
              lineArr.push(lineObj)
            }
            if (i.properties.type == 'billboard') {
              poinArr.push({
                position: i.geometry.coordinates,
                name: i.properties.style.label.text,
                pData: mapitem
              })
            }
            if (i.properties.type == 'polygon') {
              var polObj = {}
              polObj.Name = i.properties.style.label.text
              polObj.name = i.properties.style.label.text
              polObj.Type = '房屋面'
              polObj.ID = i.properties.id
              polObj.GeoType = 'polygon'
              polObj.FillColor = '#87CEEB'
              polObj.Opacity = 0.6
              polObj.Positions = i.geometry.coordinates[0] //.join(',')
              polObj.pData = mapitem
              polObj.villageId = this.baseForm.regionId
              polArr.push(polObj)
            }
          })
        }
      })
      //线数据
      linedata = lineArr
      //面
      buildingData = polArr
      //点
      bussinessData = poinArr
      console.info('线数据', linedata)
      console.info('面', buildingData)
      console.info('点', bussinessData)
      if (type) {
        // 添加线图层
        this.addCommunityLine()
        // 添加房屋面图层
        this.addBuidingLyr(buildingData)
        // 添加设备点图层
        // console.log('添加设备点图层')
        this.addBussinessLyr(bussinessData)
      }
    },
    backPre() {
      this.$router.go(-1)
      setTimeout(function() {
        window.location.reload()
      }, 30)
    },
    //多选
    handleChange(e) {
      let arr = e
      linedata = []
      //面
      buildingData = []
      //点
      bussinessData = []
      this.filterArr(arr)
    },
    filterArr(data) {
      console.log(data)
      this.polygongraphicLayer.show = false
      this.lineLayer.show = false
      this.bussinessLayer.show = false
      let arr = []
      arr = this.allList.filter(i => {
        return i?.modelType && data.includes(i.modelType)
      })
      console.log(arr)

      this.changeJson(arr, 'checked')
    },
    drow() {
      dmap = new mars3d.Map('map3DView', {
        scene: {
          center: {
            lat: 41.3996,
            lng: 119.707247,
            alt: 237,
            heading: 359,
            pitch: -37.8
          },
          requestRenderMode: true, //开启显式渲染
          showSun: true,
          showMoon: true,
          showSkyBox: true,
          showSkyAtmosphere: false, // 关闭球周边的白色轮廓 map.scene.skyAtmosphere = false
          fog: true,
          fxaa: true,
          globe: {
            showGroundAtmosphere: false, // 关闭大气（球表面白蒙蒙的效果）
            depthTestAgainstTerrain: false,
            baseColor: '#546a53'
          },
          cameraController: {
            zoomFactor: 3.0,
            minimumZoomDistance: 1,
            maximumZoomDistance: 50000000,
            enableRotate: true,
            enableZoom: true
          }
        },
        control: {
          // compass: { top: '10px', right: '10px' },
          locationBar: {
            fps: true,
            format: '<div>视高：{height}米</div><div>俯仰角：{pitch}度</div><div>方向：{heading}度</div><div>海拔：{z}米</div><div>纬度:{y}</div><div>经度:{x}</div>'
          },
          distanceLegend: false, // 新增比例尺
          baseLayerPicker: false, // basemaps底图切换按钮
          homeButton: true, // 视角复位按钮
          sceneModePicker: false, // 二三维切换按钮
          navigationHelpButton: false, // 帮助按钮
          fullscreenButton: false, // 全屏按钮
          defaultContextMenu: false // 右键菜单
        },
        terrain: {
          url: 'http://data.mars3d.cn/terrain',
          show: false
        },
        basemaps: [
          {
            name: 'ArcGIS影像',
            icon: 'img/basemaps/esriWorldImagery.png',
            type: 'arcgis',
            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
            enablePickFeatures: false,
            show: true
          }
        ]
      })
      // Vue.prototype.dmap = new mars3d.Map('map3DView', mapOptions)
      dmap.openFlyAnimation()
      // 添加倾斜摄影模型
      this.addTileSet()
      // 添加天空盒子
      // this.addSkyBox()
      dmap.changeMouseModel(true)
      const handler = new Cesium.ScreenSpaceEventHandler(dmap.scene.canvas)
      handler.setInputAction(movement => {
        // console.log(movement)
        this.pickFeatureFromScreen(movement.position.x, movement.position.y)
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
      dmap.onlyPickModelPosition = true
      this.homeDestination = new Cesium.Cartesian3.fromDegrees(119.707247, 41.3996, 237)
      this.homeOrientation = new Cesium.HeadingPitchRoll.fromDegrees(4, -62, 360)
      let currListGraphicLayer = new mars3d.layer.GraphicLayer()
      dmap.addLayer(currListGraphicLayer)
    },
    // 添加倾斜摄影模型
    addTileSet() {
      const tileSetLyr = new mars3d.layer.TilesetLayer({
        name: '小平房',
        // url: 'http://192.168.88.130:9003/model/cmkTfSB8/tileset.json',
        url: 'http://192.168.1.44:9003/model/cmkTfSB8/tileset.json',
        maximumScreenSpaceError: 16,
        maximumMemoryUsage: 4096,
        dynamicScreenSpaceError: true, //减少远离摄像头的贴图的屏幕空间误差。true时会在真正的全屏加载完之后才清晰化模型
        cullWithChildrenBounds: false, //是否使用子绑定卷的并集来筛选贴图
        skipLevelOfDetail: false,
        preferLeaves: true,
        luminanceAtZenith: 1,
        position: { alt: 140 }
      })
      const tileSetLyr1 = new mars3d.layer.TilesetLayer({
        name: '小平房',
        // url: 'http://192.168.88.130:9003/model/g1OSAMJC/tileset.json',
        url: 'http://192.168.1.44:9003/model/g1OSAMJC/tileset.json',
        maximumScreenSpaceError: 16,
        maximumMemoryUsage: 4096,
        dynamicScreenSpaceError: true, //减少远离摄像头的贴图的屏幕空间误差。true时会在真正的全屏加载完之后才清晰化模型
        cullWithChildrenBounds: false, //是否使用子绑定卷的并集来筛选贴图
        skipLevelOfDetail: false,
        preferLeaves: true,
        luminanceAtZenith: 1,
        position: { alt: 140 }
      })
      tileSetLyr.on('load', () => {
        // 添加线图层
        this.addCommunityLine()
        // 添加房屋面图层
        this.addBuidingLyr(buildingData)
        // 添加设备点图层
        // console.log('添加设备点图层')
        this.addBussinessLyr(bussinessData)
        this.drawTools = new DrawTool({
          map: dmap,
          tileSetLyr
        })
      })
      dmap.addLayer(tileSetLyr)
      dmap.addLayer(tileSetLyr1)
      this.tileSetLyr = tileSetLyr
      return tileSetLyr
    },
    // 添加天空盒子
    addSkyBox() {
      const lantianSkybox = new Cesium.SkyBox({
        nearGround: true, // 近地天空盒
        sources: {
          positiveX,
          negativeX,
          positiveY,
          negativeY,
          positiveZ,
          negativeZ
        }
      })
      const defaultSkybox = dmap.scene.skyBox
      const currSkyBox = lantianSkybox

      dmap.scene.postRender.addEventListener(() => {
        const { position } = dmap.camera
        const { height } = Cesium.Cartographic.fromCartesian(position)
        if (height < 230000) {
          if (currSkyBox) dmap.scene.skyBox = currSkyBox
          dmap.scene.skyAtmosphere.show = false
        } else {
          if (defaultSkybox) dmap.scene.skyBox = defaultSkybox
          dmap.scene.skyAtmosphere.show = true
        }
      })
    },
    // 加载线图层
    addCommunityLine() {
      this.lineLayer = new mars3d.layer.GraphicLayer()
      this.lineLayer.show = false
      dmap.addLayer(this.lineLayer)
      this.lineLayer.clear()
      //监听点击事件
      this.lineLayer.on(mars3d.EventType.click, event => {
        if (!(event.pickedObject.primitive instanceof Cesium.Label)) {
          const infoData = {
            type: 'dantihua',
            data: event.graphic.attr
          }
          console.info('点击线' + event.graphic.attr)
          // this.flyToEntity(event.graphic.attr.Name)
          // 弹窗展示
          this.$refs.mapMode.initData(event.graphic.attr)
        }
      })

      if (linedata.length) {
        linedata.map(item => {
          const graphic = new mars3d.graphic.PolylineEntity({
            positions: item.geometry.coordinates,
            attr: item,
            style: item.properties.style
          })
          this.lineLayer.addGraphic(graphic)
          this.lineLayer.show = true
        })
      } else {
        this.lineLayer.clear()
      }
    },
    // 加载房屋面的图层
    addBuidingLyr(data) {
      this.polygongraphicLayer = new mars3d.layer.GraphicLayer()
      this.polygongraphicLayer.show = false
      dmap.addLayer(this.polygongraphicLayer)
      this.polygongraphicLayer.clear()
      this.polygongraphicLayer.on(mars3d.EventType.click, event => {
        if (!(event.pickedObject.primitive instanceof Cesium.Label)) {
          const infoData = {
            type: 'dantihua',
            data: event.graphic.attr
          }
          console.info('房屋面信息弹窗' + event.graphic.attr.Name)
          // this.flyToEntity(event.graphic.attr.Name)
          // 弹窗展示
          this.$refs.mapMode.initData(event.graphic.attr)
          console.log(infoData)
        }
      })
      if (buildingData.length) {
        buildingData.map(item => {
          const positions = item.Positions //setCoordinates(item.Positions)
          const graphic = new mars3d.graphic.PolygonEntity({
            positions,
            name: item.Name,
            attr: item,
            style: {
              color: item.FillColor,
              opacity: item.Opacity,
              outline: true,
              outlineWidth: 3,
              outlineColor: '#ffffff',
              clampToGround: true
            }
          })
          // const point = graphic.centerPoint
          // const position = Cesium.Cartographic.toCartesian(Cesium.Cartographic.fromDegrees(point._lng, point._lat))
          // let height = dmap.scene.sampleHeight(position, [this.tileSetLyr])
          // if (height === undefined) {
          //   height = 10
          // }
          // const labelgraphic = new mars3d.graphic.LabelEntity({
          //   position: new mars3d.LngLatPoint(point._lng, point._lat, height),
          //   style: {
          //     text: item.Name,
          //     font: '20px 楷体',
          //     showBackground: true,
          //     backgroundColor: Cesium.Color.fromCssColorString('#0b3696').withAlpha(0.5),
          //     outlineWidth: 3,
          //     outlineColor: Cesium.Color.fromCssColorString('#000000'),
          //     style: {
          //       FILL: 0,
          //       FILL_AND_OUTLINE: 2,
          //       OUTLINE: 1
          //     },
          //     pixelOffset: new Cesium.Cartesian2(0, -100),
          //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          //     verticalOrigin: Cesium.VerticalOrigin.TOP,
          //     visibleDepth: false
          //   }
          // })
          this.polygongraphicLayer.addGraphic(graphic)
          // this.polygongraphicLayer.addGraphic(labelgraphic)
          this.polygongraphicLayer.show = true
        })
      }
    },
    // 加载业务图层-设备点、摄像头
    addBussinessLyr(data) {
      this.bussinessLayer = new mars3d.layer.GraphicLayer()
      this.bussinessLayer.show = false
      dmap.addLayer(this.bussinessLayer)
      this.bussinessLayer.clear()

      if (bussinessData.length) {
        bussinessData.map(item => {
          var { position, name } = item
          var graphic = new mars3d.graphic.BillboardEntity({
            name,
            attr: item,
            data: item,
            position: new mars3d.LngLatPoint(position[0], position[1], 10),
            style: {
              image: cameraPng,
              scale: 0.5,
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              scaleByDistance: new Cesium.NearFarScalar(10000, 1.0, 500000, 0.1),
              label: {
                showBackground: true,
                backgroundColor: Cesium.Color.fromCssColorString('#0b3696').withAlpha(0.5),
                outlineWidth: 3,
                outlineColor: Cesium.Color.fromCssColorString('#000000'),
                text: name,
                font_size: 18,
                color: '#ffffff',
                pixelOffsetY: -60,
                distanceDisplayCondition: true,
                distanceDisplayCondition_far: 500000,
                distanceDisplayCondition_near: 0
              }
            }
          })
          // console.log(graphic)
          this.bussinessLayer.addGraphic(graphic)
          this.bussinessLayer.show = true
          graphic.autoSurfaceHeight({
            has3dtiles: true
          })
        })
      }
    },
    pickFeatureFromScreen(x, y) {
      const pickCartesian2 = new Cesium.Cartesian2(x, y)
      if (this.clickMapFlag) {
        this.selectedView.position = new Cesium.Cartesian3(x, y, 0)
        this.selectedView.position.z = this.selectedView.cameraPosition.z - 3
        this.clickMapFlag = false
        document.getElementById('app').style.cursor = 'default'
      } else {
        console.log(pickCartesian2)
        console.log(dmap.scene)
        const feature = dmap.scene.pick(pickCartesian2)
        console.log(new Cesium.Cartesian2())
        console.log(feature)
        console.log(Cesium.defined(feature))
        if (Cesium.defined(feature)) {
          if (feature.primitive instanceof Cesium.Billboard) {
            if (feature.id.data) {
              // 在这里可以做设备点点击后的弹窗内容
              message.info('在这里可以做设备点点击后的弹窗内容')
              this.localtionBussinessEntity(feature.id.data.name)
              console.log(feature.id.data)
            } else {
              const data = this.bussinessLayer.getGraphicByAttr('name', feature.id.name)
              if (data) {
                // 在这里可以做设备点点击后的弹窗内容
                console.log(data.options.data)
                let item = data.options.data
                // 弹窗展示
                if (item.pData.modelType != 'monitor') {
                  this.$refs.mapMode.initData(data.options.data)
                } else {
                  //设备直接展示视频
                  this.getallCameras(item.pData.modelId)
                }
                console.info('在这里可以做设备点点击后的弹窗内容')
                this.localtionBussinessEntity(feature.id.name)
                // console.log(feature)
              }
            }
          } else if (feature.primitive instanceof Cesium.GroundPrimitive) {
            if (this.communityGridLyr && typeof feature.id === 'string') {
              const graphic = this.communityGridLyr.layer.getGraphicById(feature.id)
              this.communityGridLyr.reSetAllEntityColor()
              if (graphic) {
                this.communityGridLyr.colorEntityById(graphic.attr.Id)
                // 在这里可以做点击后的弹窗内容
                console.info('在这里可以做点击网格后的弹窗内容')
                console.log(graphic.attr)
              }
            }
          }
        }
      }
    },
    /**
     * 飞到对应id的entity 对应房屋面
     * @param {string} name
     */
    flyToEntity(name) {
      const entity = this.polygongraphicLayer.getGraphicByAttr('name', name)
      if (entity && entity.positions.length > 1) {
        const point = entity.centerPoint
        const position = Cesium.Cartographic.fromDegrees(point._lng, point._lat)
        let height = dmap.scene.sampleHeight(position, [this.tileSetLyr])
        if (height === undefined) {
          height = 10
        }
        dmap.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(point._lng, point._lat, height + 100)
        })
      } else {
        console.info('没有房屋面数据')
      }
    },
    // 飞行到设备点
    localtionBussinessEntity(name) {
      const graphic = this.bussinessLayer.getGraphicByAttr('name', name)
      if (graphic) {
        dmap.flyToGraphic(graphic, {
          radius: 50
        })
      }
    },
    getallCameras(pid) {
      this.loading = true
      getMapCameras({ id: pid }).then(res => {
        if (res.code == '0') {
          console.log(res.model)
          let urls = []
          let item = res.model
          urls.push({ url: res.model.cameraUrl })
          item.urls = urls
          this.$refs.ezuik.initData(item)
          // this.paramsData.richText = res.model.richText
        } else {
          this.loading = false
        }
      })
    }
  },

  destroyed() {
    if (dmap) dmap = undefined
    // griddata = []
    buildingData = []
    bussinessData = []

    if (this.tilesetPlanClip) {
      this.dmap.removeThing(this.tilesetPlanClip, true) //移除并销毁
      this.tilesetPlanClip = null
    }

    if (this.communityGridLyr) {
      this.communityGridLyr = null
    }
    if (this.polygongraphicLayer) {
      this.polygongraphicLayer = null
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-container {
  position: absolute;
  margin: 10px;
  // top: 10px;
  bottom: 10px;
  padding: 10px;
  background: white;
  border-radius: 4px;
  box-shadow: 2px 2px 2px grey;
  font-family: emoji;
  font-size: 18px;
  /deep/ .el-checkbox__label {
    font-size: 18px;
    padding: 10px;
  }
}
.draw {
  // top: 10px;
  // left: 400px;
  // padding: 10px;
  bottom: 10px;
  right: 0;
  padding: 10px;
}
.mapmain {
  width: 100%;
  height: 100%;
  #map3DView {
    width: 100%;
    height: 100%;
  }
}
</style>

/*
 * @Description:
 * @Autor: xjh
 * @Date: 2023-04-17 21:18:56
 * @LastEditors: xjh
 * @LastEditTime: 2023-04-17 22:09:39
 */
import dialog from "@/components/maps/dialog/index.js";
import message from "@/components/maps/message/index.js";
import { config } from "../attr";

const { mars3d } = window;

class DrawTool {
  constructor(options) {
    this.map = options.map;
    this.tileSetLyr = options.tileSetLyr;
    this.graphicLayer = new mars3d.layer.GraphicLayer();
    this.map.addLayer(this.graphicLayer);
    this.bindLayerPopup();
    this.bindLayerContextMenu();
  }

  startDraw(type) {
    console.log('11');
    if (config[type]) {
      const item = config[type];
      // console.log(item);
      if (type === "polygon" || type === "polyline") {
        this.tileSetLyr.flyTo({ pitch: -90 });
      }
      this.graphicLayer.startDraw(item);
    }
  }

  bindLayerPopup() {
    this.graphicLayer.bindPopup(function (event) {
      const attr = event.graphic.attr || {};
      attr["类型"] = event.graphic.type;
      attr["来源"] = "我是layer上绑定的Popup";
      attr["备注"] = "我支持鼠标交互";

      return mars3d.Util.getTemplateHtml({
        title: "矢量图层",
        template: "all",
        attr: attr
      });
    });
  }

  bindLayerContextMenu() {
    this.graphicLayer.bindContextMenu([
      {
        text: "开始编辑对象",
        icon: "fa fa-edit",
        show: function (e) {
          var graphic = e.graphic;
          if (!graphic || !graphic.hasEdit) {
            return false;
          }
          return !graphic.isEditing;
        },
        callback: e => {
          var graphic = e.graphic;
          if (!graphic) {
            return false;
          }
          if (graphic) {
            this.graphicLayer.startEditing(graphic);
          }
        }
      },
      {
        text: "停止编辑对象",
        icon: "fa fa-edit",
        show: function (e) {
          const graphic = e.graphic;
          if (!graphic || !graphic.hasEdit) {
            return false;
          }
          return graphic.isEditing;
        },
        callback: e => {
          const graphic = e.graphic;
          if (!graphic) {
            return false;
          }
          if (graphic) {
            graphic.stopEditing();
          }
        }
      },
      {
        text: "删除对象",
        icon: "fa fa-trash-o",
        show: event => {
          const graphic = event.graphic;
          if (!graphic || graphic.isDestroy) {
            return false;
          } else {
            return true;
          }
        },
        callback: e => {
          const graphic = e.graphic;
          if (!graphic) {
            return;
          }
          const parent = graphic.parent; // 右击是编辑点时
          this.graphicLayer.removeGraphic(graphic);
          if (parent) {
            this.graphicLayer.removeGraphic(parent);
          }
        }
      },
      {
        text: "计算长度",
        icon: "fa fa-medium",
        callback: e => {
          const graphic = e.graphic;
          const strDis = mars3d.MeasureUtil.formatDistance(graphic.distance);
          dialog.info("该对象的长度为:" + strDis);
        }
      }
    ]);
  }

  exportJson() {
    if (this.graphicLayer.length === 0) {
      message.info("当前没有标注任何数据，无需保存！");
      return;
    }
    const geojson = this.graphicLayer.toJSON();
    mars3d.Util.downloadFile("矢量数据构造参数.json", JSON.stringify(geojson));
  }
}

export default DrawTool;
